// i18next-extract-mark-ns-start capabilities-combustion

import { COMBUSTION_NAV, CAPABILITIES_PRODUCTS_COMBUSTION_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import Video from "@components/Video";
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilityCombustionPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);
	const vulcan = getImage(data.vulcan.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_title">
						<h2>Modelling in-cylinder combustion</h2>
						</Trans>
						<Video
						   videoSrcURL="https://strsmktncu.blob.core.windows.net/public-assets/article-images/Solutions/Combustion/combustion.mp4"
						   videoTitle="Combustion"
					    />
						<Trans i18nKey="content_1">
						<p>
							Internal Combustion Engine (ICE) powertrains are the backbone of powerful, efficient, and cost-effective propulsion
							in numerous sectors, spanning automotive, marine, aerospace, construction, power generation, and more.
							Realising the full potential of ICEs begins with a deep understanding of combustion processes,
							leading to cleaner combustion, superior propulsion efficiency, emissions reduction, and a more sustainable economy.
						</p>

						<p>
							At Realis, we are passionate about delivering accurate, flexible, and fast combustion modelling capabilities for our customers.
							This drive underpins our combustion modelling from the inception with correct property definition and blending of
							complex fuels through modelling of combustion kinetics and flame propagation and to the analysis of the effect of the
							combustion on power, thermal and emissions response of your ICE powertrains.
							Realis Simulation offers a comprehensive modelling capability – from conventional gasoline, diesel, and kerosene blends to
							next-generation drop-in biodiesels, synthetic hydrocarbons, non-carbon fuels such as hydrogen and ammonia and revolutionary e-fuels,
							empowering our customers.
						</p>
						<p>
							Our simulation toolsets can quickly and accurately provide answers to the ‘What ifs’ which are vital to the overall assessment
							of different fuel types.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							WAVE is a 1D Computational Fluid Dynamics (CFD) that solves the Navier-Stokes equations governing the transfer of mass,
							momentum, and energy for compressible gas flows, and includes sub-models for combustion and emissions.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							VECTIS is a 3D CFD tool that uses cutting edge kinetics, flame propagation and emissions models
							for conventional hydrocarbons, e-fuels and synthetic fuels including hydrogen.
						</p>
					</Trans>

					{vulcan && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vulcan}
								alt="FEARCE-Vulcan"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_4">
						<p>
							FEARCE-Vulcan uses a unique iterative Finite Element solution that considers all the heat paths in detail to deliver a reliable
							and accurate predictive tool for conclusive structural investigations.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CleanCombustion")}
						className="widget__sibling__pages">
						<AssetList>
							{COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default CapabilityCombustionPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-combustion", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/combustion/carbon/15TSI_MultiCyl_view_top_zoom_t_270p00CAD_cyc_2_061_cropped_1440x573.png" }) {
		...imageBreaker
	}
	wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
		...imageBreaker
	}
	vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
		...imageBreaker
	}
	vulcan: file(relativePath: { eq: "content/rs_fearce_vulcan.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { capabilities: { in: "Combustion" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;